.home_header_background {
  background: #ffffff !important;
  border: 1px solid rgba(176, 176, 176, 0.2) !important;
  box-sizing: border-box !important;
  box-shadow: 0px 16px 32px -8px rgba(0, 0, 0, 0.1) !important;
  border-radius: 3px !important;
}
.header_logo_color {
  width: 100%;
  height: 47px;
}
.header_flex_grow {
  display: flex;
  justify-content: center;
  width: 100%;
}

.header_flex_grow p {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  padding: 0% 3%;
  margin-bottom: 0px !important;
}
.header_flex_grow a {
  color: #000000 !important;
  outline: none !important;
  text-decoration: none !important;
}
.header_home_flex_content {
  flex-grow: 1;
}
.home_header_logo_padding {
  padding-top: 10px;
}
.home_footer_main_div {
  background: #1f55e1;
  padding: 1% 5%;
  color: #ffffff;
}
.home_footer_flex {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.home_footer_flex_grow {
  flex-grow: 1;
}
.home_footer_font_size {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  margin-bottom: 0px !important;
}
.home_footer_copy_right {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.footer_icon_color {
  font-family: Font Awesome 5 Brands;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;

  color: #ffffff;
}
.footer_icon {
  width: 10%;
  display: flex;
  justify-content: space-around;
}
